import React from "react";

// import { Link } from "gatsby";
import AnchorLink from "react-anchor-link-smooth-scroll";
import AniLink from "gatsby-plugin-transition-link/AniLink";

import classNames from "classnames";

// material ui:
import withStyles from "@material-ui/core/styles/withStyles";

// React icons
import { FaPlay } from "react-icons/fa";

// header components:

import Video from "../components/Testvideo.jsx";

// main components:

import GridContainer from "../components/Grid/GridContainer.jsx";
import GridItem from "../components/Grid/GridItem.jsx";
import Button from "../components/CustomButtons/Button.jsx";
import Parallax from "../components/Parallax/Parallax.jsx";
import Header from "../components/Header/Header.jsx";
import HeaderLinks from "../components/Header/HeaderLinks.jsx";
import SEO from "~/components/seo";

// headerlinks:

import componentsStyle from "../assets/jss/material-components/views/components.jsx";

import bottles from "../assets/img/cainallbottlesreverse.png";
import mask from "../assets/img/mask.png";
import FitVideo from "../assets/img/maskvideo.mp4";
import cain from "../assets/img/cainbycs.png";
import cainflowers from "../assets/img/cainflowers.png";
import cainb2 from "../assets/img/caincutoutshadow2bs.png";
import videobg from "../assets/img/bubbles1.mp4";
import videobg2 from "../assets/img/vid2.mp4";
import hands from "../assets/img/hands.mp4";
import ProductGrid from "~/components/ProductGrid";
import TransitionLink from "gatsby-plugin-transition-link";

// sections:

import ProductSection1 from "./ProductSection1.jsx";

class Homepage extends React.Component {
  render() {
    const { classes, ...rest } = this.props;
    return (
      <div>
        <SEO
      title="Cain PPE"
      keywords={[`Hand Sanitizer`, `Cain Hand Sanitizer`, `PPE`, `Hygiene`, `Cain PPE`, `Cain`]}
    />
        <nav
          className={classes.navgroup}
          data-sal="slide-up"
          data-sal-delay="900"
          data-sal-easing="ease"
        >
          <ul className={classes.navlist}>
            <AnchorLink href="#intro">
              <li className={classes.navitem}>
                <span className={classes.navnumber}>01.</span>Indroduction
              </li>
            </AnchorLink>
            <AnchorLink href="#why" offset="-300">
              <li className={classes.navitem}>
                <span className={classes.navnumber}>02.</span>Why Cain
              </li>
            </AnchorLink>
            <AnchorLink href="#about" offset="300">
              <li className={classes.navitem}>
                <span className={classes.navnumber}>03.</span>About Cain
              </li>
            </AnchorLink>
            <AniLink
              paintDrip
              to="/product/cain-hand-sanitiser-500ml-atomising-spray-retail/"
              hex="#4C83BE"
            >
              <li className={classes.navitem}>
                <span className={classes.navnumber}>04.</span>Buy Cain
              </li>
            </AniLink>
          </ul>
        </nav>
        <div className={classes.headervid} id="intro">
          <img alt="Cain logo" src={cain} className={classes.cainimage} />
          <video
            className={classes.videoplayer}
            height="100%"
            width="100%"
            loop
            muted
            autoPlay
          >
            <source src={videobg} type="video/mp4" />
          </video>
        </div>
        {/* <div className={classes.skewwrapper}>
          <div className={classes.skewcontainer}></div>
        </div> */}
        <div className={classes.container}>
          <GridContainer>
            <GridItem xs={7} sm={6} md={6}>
              <h1
                className={classes.introducing}
                data-sal="slide-right"
                data-sal-delay="500"
                data-sal-easing="ease"
              >
                Introducing
              </h1>
              <h1 className={classes.caintext}>Cain</h1>
              <h5 className={classes.introtag}>
                Cain hand sanitiser is a British made hand sanitiser which
                contains 80% ethanol, making it one of the strongest virus
                eliminating hygiene sanitisers on the market.
              </h5>
              <h5 className={classes.introtag2}>
                This spray has a wide range of applications including hands,
                door surfaces, work tops, desks and other public surfaces.
              </h5>

              {/* <Link to="/products">
                <Button
                  color="primary"
                  size="lg"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <div className={classes.buttontext}>VISIT STORE</div>
                </Button>
              </Link> */}
            </GridItem>
            <GridItem xs={4} sm={5} md={6}>
              <div className={classes.imagewrap}>
                <img alt="..." src={bottles} className={classes.bottleimage} />
              </div>
            </GridItem>
            {/* <GridItem xs={12}>
              <Link to="/cain-sanitiser">
                <div className={classes.linkwrap}>
                  <div className={classes.cainlink}>Our Hand Santizer</div>
                </div>
              </Link>
            </GridItem> */}
          </GridContainer>
        </div>
        <div className={classes.headervid2} id="why">
          <img alt="Cain logo" src={cain} className={classes.cainimage2} />
          <video
            className={classes.videoplayer2}
            height="100%"
            width="100%"
            loop
            muted
            autoPlay
          >
            <source src={videobg2} type="video/mp4" />
          </video>
        </div>
        <div className={classes.container}>
          <ProductSection1 />
        </div>
        <div className={classes.paddingtop}></div>

        <div className={classes.container} id="about">
          <GridContainer
            direction="row"
            justify="center"
            alignItems="flex-start"
          >
            <GridItem xs={12} sm={7} md={7}>
              <div className={classes.aboutwrap}>
                <h1 className={classes.about}>About</h1>
                <h1 className={classes.caintext}>Cain</h1>
              </div>
              <div className={classes.imagewrapabout}>
                <img
                  alt="..."
                  src={cainb2}
                  className={classes.twobottleimage}
                />
              </div>
            </GridItem>
            <GridItem xs={12} sm={5} md={5}>
              <div className={classes.productdesc}>
                <span className={classes.number}>80%</span>
                <br />
                <span className={classes.ethanol}>ethanol</span>
                <div className={classes.iintro}>
                  Manufactered in the UK with the utmost thought and care using
                  the following ingredients:
                </div>
                <p className={classes.ingredients}>
                  Ethanol 80%, Glycerine 1.5%, Hydrogen Peroxide 0.125%
                </p>
                <AniLink
                  paintDrip
                  to="/product/cain-hand-sanitiser-500ml-atomising-spray-retail/"
                  hex="#4C83BE"
                >
                  <Button color="primary" size="xs">
                    Buy Now
                  </Button>
                </AniLink>
              </div>
            </GridItem>
          </GridContainer>
        </div>
        <div className={classes.container}>
          <div className={classes.headervid3}>
            <img alt="Cain logo" src={cain} className={classes.cainimage2} />
            <video
              className={classes.videoplayer3}
              height="100%"
              width="100%"
              loop
              muted
              autoPlay
            >
              <source src={hands} type="video/mp4" />
            </video>
          </div>
        </div>
      </div>
    );
  }
}

export default withStyles(componentsStyle)(Homepage);
