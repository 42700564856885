import React, { useContext } from "react";
import { useStaticQuery, graphql, Link } from "gatsby";

import styled from "@emotion/styled";
import GridContainer from "../Grid/GridContainer";
import GridItem from "../Grid/GridItem";
import Badge from "../Badge/Badge.jsx";
import Button from "../CustomButtons/CartButton.jsx";
import StoreContext from "~/context/StoreContext";
// import { Grid, PriceTag } from "./styles";
// import { Img } from "~/utils/styles";

export const Imagewrap = styled.div`
  img {
    border-style: none;
    max-width: 80%;
    height: auto;
    margin-left: 30%;
    border-radius: 5px 5px;
    opacity: 0.93;
    @media (max-width: 600px) {
      margin-left: 0%;
    }
  }
`;

export const Product = styled.div`
  margin-top: 5rem;
  margin-bottom: 5rem;
  @media (max-width: 600px) {
    margin-bottom: 7rem;
  }
`;

export const Title = styled.span`
  font-weight: 500;
  font-size: 1.2rem;
  text-align: left;
  color: #040707;
  opacity: 0.9;
  background: #ffffff;
`;
export const ProductDescription = styled.div`
  margin-top: 5px;
  font-family: "Montserrat", "Montserrat", sans-serif;
  color: #ffffff;
  font-weight: 300;
  padding-right: 2rem;
  border-radius: 3px;
  padding: 2.5rem 2.5rem 3rem 2.5rem;
  opacity: 0.99;
  background: linear-gradient(
    #080c0c 0%,
    #292d2d 0%,
    #0d171b 7.5%,
    #101b21 13.57%,
    #192735 38.33%,
    #203042 100%
  );
`;

export const Dummy = styled.div`
  z-index: 10;
`;

const ProductGrid = () => {
  const {
    store: { checkout },
  } = useContext(StoreContext);
  const { allShopifyProduct } = useStaticQuery(
    graphql`
      query {
        allShopifyProduct(
          sort: { fields: [createdAt], order: DESC }
          filter: { tags: { eq: "retail" } }
        ) {
          edges {
            node {
              id
              tags
              title
              handle
              descriptionHtml
              createdAt
              images {
                id
                originalSrc
              }
              variants {
                price
              }
            }
          }
        }
      }
    `
  );

  const getPrice = (price) =>
    Intl.NumberFormat(undefined, {
      currency: checkout.currencyCode ? checkout.currencyCode : "EUR",
      minimumFractionDigits: 2,
      style: "currency",
    }).format(parseFloat(price ? price : 0));

  return (
    <Dummy>
      {allShopifyProduct.edges ? (
        allShopifyProduct.edges.map(
          ({
            node: {
              id,
              handle,
              title,
              descriptionHtml,
              images,
              variants: [firstVariant],
            },
          }) => (
            <Product key={id}>
              <GridContainer
                direction="row"
                justify="center"
                alignItems="center"
              >
                <GridItem spacing={0} md={6} sm={4} xs={8}>
                  <Link to={`/product/${handle}/`}>
                    <Imagewrap>
                      <img src={images[0].originalSrc} alt={title}></img>
                    </Imagewrap>
                  </Link>
                </GridItem>

                <GridItem md={6} sm={8} xs={12}>
                  <Title>{title}</Title>
                  <ProductDescription
                    dangerouslySetInnerHTML={{ __html: descriptionHtml }}
                  />
                  <Badge color="secondary">
                    {getPrice(firstVariant.price)}
                  </Badge>
                  <Link to={`/product/${handle}/`}>
                    <Button
                      color="secondary"
                      size="lg"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <div>Product Details</div>
                    </Button>
                  </Link>
                </GridItem>
              </GridContainer>
            </Product>
          )
        )
      ) : (
        <p>No Products found!</p>
      )}
    </Dummy>
  );
};

export default ProductGrid;
