import { container, title } from "../../material-kit-react.jsx";

const componentsStyle = (theme) => ({
  navgroup: {
    position: "fixed",
    top: "35vh",
    right: "4%",
    zIndex: "1000",
    opacity: "0.9",
    [theme.breakpoints.down("md")]: {
      right: "2%",
      top: "25vh",
    },
    [theme.breakpoints.down("sm")]: {
      right: "2%",
    },
    [theme.breakpoints.down("xs")]: {
      visibility: "hidden",
    },
  },
  navlist: {
    listStyle: "none",
    padding: "0",
  },
  navitem: {
    marginBottom: "0.10rem",
    fontWeight: "400",
    fontSize: "0.8rem",
    color: "#4c83be",
    [theme.breakpoints.down("md")]: {
      fontSize: "0.7rem",
    },
    [theme.breakpoints.down("sm")]: {
      fontSize: "0.6rem",
    },
  },
  navnumber: {
    margin: "0 1rem",
    fontWeight: "800",
    color: "#3b6490",
    fontSize: "0.9rem",
    borderBottom: "2px solid #3b6490",
    [theme.breakpoints.down("md")]: {
      fontSize: "0.8rem",
      margin: "0 0.4rem",
    },
    [theme.breakpoints.down("sm")]: {
      fontSize: "0.7rem",
      margin: "0 0.5rem",
    },
  },

  headervid: {
    height: "350px",
    background: "linear-gradient( #06264c 0%, #03244a 40%, #041325 100%)",
    opacity: "0.9",
    [theme.breakpoints.down("xs")]: {
      height: "200px",
      opacity: "0.9",
    },
  },
  videoplayer: {
    opacity: "0.05",
    // [theme.breakpoints.down("xs")]: {
    //     visibility: "hidden",
    //   },
  },
  cainimage: {
    width: "200px",
    margin: "0 auto",
    overflow: "hidden",
    position: "absolute",
    top: "6rem",
    bottom: "0rem",
    left: "0",
    right: "0",
    marginLeft: "auto",
    marginRight: "auto",
    opacity: "0.20",
    [theme.breakpoints.down("xs")]: {
      opacity: "0.30",
      width: "100px",
      top: "2rem",
    },
  },
  cainimage2: {
    width: "250px",
    margin: "0 auto",
    overflow: "hidden",
    position: "absolute",
    top: "6rem",
    bottom: "0rem",
    left: "0",
    right: "0",
    marginLeft: "auto",
    marginRight: "auto",
    opacity: "0.40",
    [theme.breakpoints.down("xs")]: {
      width: "150px",
      top: "3rem",
    },
  },
  bgcontainer: {
    background: "linear-gradient(#fff 0%, #e6e6e6 94.56%, #dedede 100%)",
  },
  bgcontainerpp: {
    background:
      "linear-gradient(#fff 0%, #e6e6e6 40%, #dedede 60%, #e6e6e6 70%, #DEDEDE 80%, #e6e6e6 90%, #fff 100%)",
  },
  bgcontainer2: {
    height: "100px",
    background: "linear-gradient(#dedede 0%, #fff 100%)",
  },
  // skewwrapper: {
  //   position: "relative",
  // },
  // skewcontainer: {
  //   position: "absolute",
  //   top: "0",
  //   transform: "skewX(35deg)",
  //   height: "200px",
  //   marginTop: "-2rem",
  // },
  bgstyle: {
    background:
      "linear-gradient(#fff 0%, #e6e6e6 23.64%, #dedede 50%, #e6e6e6 70.92%, #fff 100%)",
    marginBottom: "15rem",
  },
  container: {
    zIndex: "12",
    marginTop: "2rem",
    color: "#040707",
    ...container,
    [theme.breakpoints.down("md")]: {
      marginTop: "2rem",
    },
    [theme.breakpoints.down("sm")]: {
      marginTop: "2rem",
    },
    [theme.breakpoints.down("xs")]: {
      marginTop: "2rem",
    },
  },
  containerpp: {
    zIndex: "12",
    marginTop: "150px",
    color: "#040707",
    ...container,
  },
  container2: {
    zIndex: "12",
    color: "#040707",
    ...container,
  },
  container3: {
    zIndex: "12",
    color: "#040707",
    marginBottom: "4rem",
    ...container,
  },
  title: {
    ...title,
    display: "inline-block",
    position: "relative",
    minHeight: "32px",
    color: "#ffffff",
    opacity: "0.9",
    fontWeight: "600",
    textDecoration: "none",
    marginTop: "0.5rem",
    fontSize: "3.3125rem",
    [theme.breakpoints.down("md")]: {
      fontSize: "2.8125rem",
    },
    [theme.breakpoints.down("sm")]: {
      fontSize: "2.1125rem",
    },
    [theme.breakpoints.down("xs")]: {
      fontSize: "1.5rem",
    },
  },
  introducing: {
    fontSize: "1.6rem",
    fontWeight: "600",
    color: "#5da1eb",
    marginLeft: "4.75rem",
  },
  caintext: {
    fontSize: "5.6rem",
    fontWeight: "800",
    textTransform: "uppercase",
    position: "absolute",
    WebkitBackgroundClip: "text",
    marginTop: "-1.5rem",
    backgroundImage: "linear-gradient(135deg,#1e3044,#65afff)",
    WebkitTextFillColor: "transparent",
    marginLeft: "0rem",
  },
  titlecain: {
    ...title,
    display: "inline-block",
    position: "relative",
    minHeight: "32px",
    color: "#040707",
    textDecoration: "none",
    marginTop: "0.5rem",
    fontWeight: "600",
  },
  title2: {
    ...title,
    display: "inline-block",
    position: "relative",
    minHeight: "32px",
    color: "#040707",
    textDecoration: "none",
    marginTop: "0.5rem",
    marginBottom: "3rem",
    [theme.breakpoints.down("xs")]: {
      fontSize: "1.5rem",
    },
  },
  introtag: {
    fontWeight: "400",
    position: "relative",
    marginTop: "7rem",
    opacity: "0.75",
    marginBottom: "3rem",
    paddingRight: "3rem",
    fontSize: "15px",
    [theme.breakpoints.down("xs")]: {
      paddingRight: "0rem",
      marginLeft: "1.5rem",
      fontSize: "12px",
    },
  },

  introtag2: {
    fontWeight: "400",
    marginTop: "-1rem",
    position: "relative",
    opacity: "0.75",
    paddingRight: "3rem",
    fontSize: "15px",
    [theme.breakpoints.down("xs")]: {
      paddingRight: "0rem",
      marginLeft: "1.5rem",
      fontSize: "12px",
    },
  },

  headervid2: {
    marginTop: "50px",
    marginLeft: "5%",
    position: "relative",
    marginRight: "5%",
    height: "400px",
    background: "#99c8ff",
    [theme.breakpoints.down("md")]: {
      marginTop: "50px",
      marginLeft: "5%",
      marginRight: "5%",
      height: "400px",
      background: "#99c8ff",
    },
    [theme.breakpoints.down("sm")]: {
      marginTop: "50px",
      marginLeft: "3.5%",
      marginRight: "3.5%",
      height: "400px",
      background: "#99c8ff",
    },
    [theme.breakpoints.down("xs")]: {
      marginLeft: "4%",
      marginRight: "4%",
      marginTop: "50px",
      height: "250px",
      background: "#99c8ff",
    },
  },
  videoplayer2: {
    opacity: "0.05",
  },
  headervid3: {
    marginTop: "1rem",
    marginBottom: "10rem",
    marginLeft: "22%",
    position: "relative",
    marginRight: "22%",
    height: "380px",
    background: "#3b4f67",
    [theme.breakpoints.down("md")]: {
      marginTop: "50px",
      marginLeft: "15%",
      marginRight: "15%",
      height: "395px",
      background: "#3b4f67",
    },
    [theme.breakpoints.down("sm")]: {
      marginTop: "50px",
      marginLeft: "10%",
      marginRight: "10%",
      height: "350px",
      background: "#3b4f67",
    },
    [theme.breakpoints.down("xs")]: {
      marginLeft: "0%",
      marginRight: "0%",
      marginTop: "50px",
      height: "235px",
      background: "#527eb3",
      marginBottom: "15rem",
    },
  },
  videoplayer3: {
    opacity: "0.03",
  },

  paragraph: {
    fontSize: "14px",
    [theme.breakpoints.down("xs")]: {
      fontSize: "14px",
    },
  },
  subtitle: {
    fontSize: "1.313rem",
    maxWidth: "500px",
    margin: "10px auto 0",
  },
  main: {
    background: "#FFFFFF",
    position: "relative",
    zIndex: "3",
  },
  mainRaised: {
    margin: "-60px 30px 0px",
    borderRadius: "6px",
    boxShadow:
      "0 16px 24px 2px rgba(0, 0, 0, 0.14), 0 6px 30px 5px rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(0, 0, 0, 0.2)",
  },
  imagewrap: {
    width: "100%",
    height: "auto",
    overflow: "hidden",
    marginTop: "-7rem",
    [theme.breakpoints.down("md")]: {},
    [theme.breakpoints.down("sm")]: {
      marginTop: "-4rem",
    },
    [theme.breakpoints.down("xs")]: {
      marginLeft: "0rem",
    },
  },
  imagewrapabout: {
    width: "100%",
    height: "auto",
    overflow: "hidden",
    marginTop: "-3rem",
    marginLeft: "14rem",
    [theme.breakpoints.down("md")]: {},
    [theme.breakpoints.down("sm")]: {
      marginTop: "-4rem",
      marginLeft: "8rem",
    },
    [theme.breakpoints.down("xs")]: {
      marginLeft: "0rem",
    },
  },
  imagewrappp: {
    marginLeft: "2rem",
    marginTop: "6rem",
    width: "100%",
    height: "auto",
    overflow: "hidden",
    [theme.breakpoints.down("xs")]: {
      marginLeft: "0rem",
    },
  },
  bottleimage: {
    width: "90%",
    overflow: "hidden",
    opacity: "0.98",
    [theme.breakpoints.down("sm")]: {
      width: "104%",
      marginTop: "150px",
    },
    [theme.breakpoints.down("xs")]: {
      width: "105%",
      marginTop: "300px",
    },
  },
  twobottleimage: {
    width: "65%",
    overflow: "hidden",
    opacity: "0.90",
    [theme.breakpoints.down("sm")]: {
      width: "94%",
      marginTop: "20px",
    },
    [theme.breakpoints.down("xs")]: {
      width: "53%",
      marginTop: "90px",
    },
  },
  bottleimagepp: {
    width: "90%",
    margin: "0 auto",
    overflow: "hidden",
    opacity: "0.95",
    [theme.breakpoints.down("sm")]: {
      width: "97%",
    },
    [theme.breakpoints.down("xs")]: {
      width: "106%",
    },
  },
  imagewrap2: {
    paddingTop: "200px",
    width: "100%",
    height: "auto",
    overflow: "hidden",
    [theme.breakpoints.down("sm")]: {
      paddingTop: "150px",
    },
  },
  linkwrap: {
    width: "100%",
    height: "200px",
    position: "relative",
  },
  cainlink: {
    position: "absolute",
    right: "10%",
    top: "-5%",
  },
  about: {
    fontSize: "1.6rem",
    fontWeight: "600",
    color: "#5da1eb",
    marginLeft: "9.25rem",
  },
  aboutwrap: {
    marginTop: "-3rem",
    marginLeft: "2rem",
    [theme.breakpoints.down("sm")]: {
      marginTop: "-3.5rem",
      marginBottom: "3rem",
    },
    [theme.breakpoints.down("xs")]: {
      marginTop: "-1rem",
      marginBottom: "3rem",
    },
  },
  productdesc: {
    marginBottom: "1rem",
    marginTop: "12rem",
    marginLeft: "-1rem",
    [theme.breakpoints.down("sm")]: {
      marginBottom: "8rem",
      marginTop: "10rem",
      marginLeft: "2rem",
    },
    [theme.breakpoints.down("xs")]: {
      marginBottom: "1rem",
      marginTop: "0rem",
      marginLeft: "1rem",
    },
  },
  number: {
    fontWeight: "900",
    fontSize: "5.6rem",
    letterSpacing: "0.05rem",
    [theme.breakpoints.down("xs")]: {
      fontSize: "4.6rem",
    },
  },
  ethanol: {
    fontSize: "1rem",
    fontWeight: "700",
    lineHeight: "1.5",
    borderBottom: "5px solid #5c9fe7",
    marginBottom: "10rem",
    [theme.breakpoints.down("xs")]: {
      fontSize: "0.8rem",
    },
  },
  iintro: {
    fontSize: "13px",
    marginTop: "1rem",
    opacity: "0.9",
    paddingRight: "16rem",
    [theme.breakpoints.down("md")]: {
      paddingRight: "10rem",
    },
    [theme.breakpoints.down("sm")]: {
      paddingRight: "0rem",
    },
    [theme.breakpoints.down("xs")]: {
      fontSize: "12px",
    },
  },
  ingredients: {
    fontSize: "12px",
    fontWeight: "500",
    marginTop: "1rem",
    marginBottom: "1rem",
    [theme.breakpoints.down("xs")]: {
      fontSize: "11px",
    },
  },
  maskimage: {
    width: "200%",
    margin: "0 auto",
    overflow: "hidden",
    [theme.breakpoints.down("sm")]: {
      width: "170%",
    },
  },
  subheading: {
    color: "#fff",
    opacity: "0.4",
    fontSize: "1rem",
    [theme.breakpoints.down("md")]: {
      fontSize: "0.9rem",
    },
    [theme.breakpoints.down("sm")]: {
      fontSize: "0.9rem",
    },
    [theme.breakpoints.down("xs")]: {
      fontSize: "0.7rem",
    },
  },
  subheadingpp: {
    opacity: "0.8",
    marginTop: "180px",
    [theme.breakpoints.down("xs")]: {
      fontSize: "0.6rem",
      marginTop: "50px",
    },
  },
  buttontext: {
    wordSpacing: "0.25rem",
    letterSpacing: "0.15rem",
  },
  vidcontainer: {
    position: "relative",
    paddingBottom: "56.25%",
    height: "0",
  },
  vidpad: {
    padding: "100px 0",
    [theme.breakpoints.down("sm")]: {
      padding: "50px 0",
    },
  },
  fitvid: {
    width: "100% !important",
    height: "auto !important",
    paddingBottom: "10rem",
    [theme.breakpoints.up("md")]: {
      padding: "75px 20%",
    },
  },
  csbrand: {
    marginTop: "-1rem",
  },
  testerdesc: {
    opacity: "0.6",
    fontSize: "0.65rem",
  },
  paddingtop: {
    marginBottom: "8rem",
  },
});

export default componentsStyle;
